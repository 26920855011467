import { createSlice } from '@reduxjs/toolkit';
import { string } from 'prop-types';

const initialState = {
  topbarBg: 'primary',
  customizerSidebar: false,
  isRTL: false,
  isDark: false,
  isMiniSidebar: false,
  sidebarBg: 'white',
  isTopbarFixed: true,
  isMobileSidebar: true,
  isSidebarFixed: true,
  isLayoutHorizontal: false,
  isInnerRightPart: false, // this is for the three column layout right part show hide in table & mobile
  sidebarMenuActive: '',
  sidearMenuNodeActive: '',
  confirmDialogStatus: { 'status': false, data: { message: '', payload: {} } },
  mainMenuActive: '',
  switchPortal: false,
  driveItemRename: false,
  driveItemLabels: false,
  driveItemVersions: false,
  driveItemPermission: false,
  recyclebinEmptyFlag: false,
  configureSmartFolderFlag: false,
  driveItemCheckout: false,
  doubleClickedFolderItem: { click: false, data: { data: null } },
  driveTileView: false,
  selectedportal: {},
  bootsrapApp: false,
  profileInfo: {},
  portalUser: [],
  userPortal: {},
  currentWeather: { data: null },
  PivotStarter: { pivot: null },
  LanguageState: 'en',
  AcceptedCookieState: {
    popupState: false,
    cookieState: false
  },
  PivotDriverSidebar: {
    pivotData: undefined,
    pivotStarter: undefined,
    url: undefined
  },

  PivotAdminSidebar: {
    pivotData: '',
    pivotStarter: {
      pivot: null
    },
    url: ''
  },
  loading: true,
  portalWorkspaces: [],
  adminWorkspaceUserId: null,
  quickViewSmartFolders: [],
  putquickViewSmartFolders: {},
  quickViewSmartFolderTriggerState: {},
  portalId: null,
  portalMemberData: [],
  portalMemberPermissionData: [],
  portalMemberTableHeader: [],
  invitationTableHeader: [],
  labelTableHeader: [],
  workspaceTableHeader: [],
  groupTableHeader: [],
  memberTableHeader : [],
  DriveSidebarDataCache: [],
  membersDetails: [],
  fetchMembersDetails: [],
  userMembersDetails: {},
  addUserDetails: [],
  portalData: [],
  memberLeftMenu: [],
  membersLeftMenuCache: [],
  getMembersLeftMenuDetails: [],
  userPortalMenuData: [],
  adminAllowedClientData: [],
  activityHistoryData: [],
  activityHistoryElements: [],
  adminInvitationData: [],
  adminLabelData: {},
  adminLabelTrigger: null,
  adminLeftMenuCache: [],
  allowedClientDetails: {},
  GroupsData: [],
  GroupDetail: {},
  HomeMemberDetails: {},
  HomeMembersData: {},
  FolderDetail: {},
  fetchFolderDetail: {},
  DeletePortalMember: {},
  fetchRecyclbin: string,
  putRecyclbin: {},
  portalMemberTableData: [],
  profileImage: {},
  smartFolder: [],
  favFolder: [],
  addFolderCache: [],
  favFolderCache: [],
  ErrorState: {
    status: false,
    message: 'Something Went Wrong!',
    modalOpen: false
  },
  activityOffSet: {
    last_offset: '',
    has_more: false
  },
  activityData: {},
  getActivityData: [],
  quickViewSmartFolderTree: [],
  addSmartFolderCache: [],
  putSmartFolderCache: [],
  getSmartFavFolderCache: [],
  favSmartFolderCache: [],
  insertSmartFolderCache: [],
  triggerworkspaceGroups: {},
  workspaceGroups: [],
  addInviteState: [],
  addWorkSpace: {},
  resWorkSpace: {},
  labels: [],
  labelStore:{entries:[],has_more:"",next_offset:""},
  breadcrubDrive: "",
  fetchDriveCache: {},
  driveSidebarCacheData: {},
  driveSidebarCache: [],
  DeleteInvitation: {},
  confirmDeleteDialogStatus: { 'status': false, data: { message: 'Deleted Succeesfully' } },
  confirmationDelete: false,
  sentWorkSpaceCopy: {},
  removeWorkSpace: {},
  commonApiStatus: {},
  resendInvite: {},
  renameWorkSpace: {},
  sendDefaultSyncStatus: {},
  toastDialogModal: { status: false, message: { header: null, description: null, body: null } },
  WorkspacePermission: {},
  labeladdState: {},
  workspaceUserforDeleteState: {},
  addUserWrkSpace: {},
  labelupdateState: {},
  portalPermissionState: {},
  labelDeleteState: {},
  removeGroup: {},
  addGroup: {},
  groupMembers: {},
  saveGroupMembers: {},
  searchFile: {},
  savedFiles: {},
  fetchWorkspaceMember: {},
  workSpaceMemebers: {},
  deleteGroupMember: {},
  sentGrpMember: {},
  grpLoader: {},
  multipleAllowedClientState: [],
  dndFileUpload: {},
  fetchDriveItems: {},
  driveItems: {},
  driveLoading: false,
  driveError: { status: false, message: '' },
  editGroupName: {},
  loggedUserPermission: '',
  loggedInUserPermissionState: [],
  clearRecyclebinTriggerState: {},
  createSmartFolderState: {},
  fetchSmartFolderTriggerState: {},
  fetchSmartFolderState: [],
  fetchSmartFolderMetaFieldState : {},
  populateSmartFolderMetaFieldState : {},
  recycleBinRow:{},
  recyceBinItemFlag:false,
  deleteWorkSpaceItems:{},
  configureSmartFolder : {},
  driveSelectedItem: {},
  deleteSmartFolder : {},
  folderDownload: {},
  folderDownloadLink: {},
  fileFolder:{},
  createmetafieldexistingsmartfolderstate : {},
  emailLink:{},
  storeEmail:{},
  linkAccesPayload: {},
  deleteMetaFieldSmartFolderState : {},
  filePreview: {},
  filePreviewLink:{},
  viewId:{},
  filePermission:{},
  storeFolderPermission:{
              GroupsData:"",
              fetchMembersDetails:"",
              storeFolderPermission:"",
              workSpaceMembers:""},
  userFilePermission:{},
  userFolderPermission:{},
  driveEditOperations: undefined,
  drivePasteOperations: undefined,
  getVersions:{},
  storeVersions:{},
  fileComment:{},
  editComment:false,
  smartFolderSelectedItem : {},
  driveSendLinkState : {},
  smartFolderMetaFieldFilterCrieteriaTrigger : {},
  smartFolderMetaFieldFilterCrieteriaState : [],
  userPermission:{},
  updatedPermission:{},
  postDriveLabelState : {},
  putDriveLabelState : {},
  deleteDriveLabelState : {},
  fileOnOfStatus: {},
  deleteFileFolderDriveState : {}, 
  renameFileFolderDriveState : {},
  previewStatus:{status:false,id:"",fileName:"",originalId:""},
  deleteSmartFolderState : {},
  configureSmartFolderTriggerState : {},
  ComponentCardName : "",
  recyclebinNavStates : {

  },
  LabelEditStatus : {
    disabled : false,
    dispatch : false 
  },
  memberListInvite : [],
  memberInviteSuccess : [],
  memberInviteError : [],
  breadCrumbDisable: false,
  adminInvitationToastState: { status: false, message: { header: null, description: null, body: null } },
  checkDeleteState:{},
  deleteState:{},
  sendLinkAccess : false,
  copyLinkAccess : false,
  ChangeModalStatus : true,
  portalWorkspacesLoading: false,
  dndFileUploadNew : {},
  driveUploadError : [],
  popupOnSubmission : false,
  driveUploadErrorSwitch : false,
  driveUploadSuccess : [],
  deleteCache:{},
  emptyPortalAlert: false,
  bootsrapLoader: true,
  signout: true,
  commonLoader:false,
  profileState:"",
  successMetaDataSmartFolder : [],
  AppDateTimeState : {
    AppDateFormat : '',
    AppTimeFormat : ''
  },
  SmartFolderNameStateTrigger : {},
  LoggedUserPortalPermissionState : [],
  LoggedUserPortalPermissionTrigger : {},
  LoggedUserWorkspacePermissionState : {},
  LoggedUserWorkspacePermissionTrigger : {},
  DirectoryUploadTrigger: {},
  DirectoryUploaded: {},
  DragEventData: {},
  DragMoveData: {},
  driveViewModal:false,
  uploadFailedStatus : {
    status : ''
  },
  uploadPopupFailedStatus : {
    status : '',
    modalOpen : false
  },
  IcoordinatorAbout : false,
  driveDeleteItem:false,
  getHighestFileFolder:{},
  highestFileFolderPrem:{},
  getprevFileState:{},
  prevFileState:{},
  lockState:{state:false,lockOwner:false},
  AllCities : [],
  SelectedCity : {
    country: "SE",
    name: "Luleå",
    lat: "65.58415",
    lng: "22.15465"
},
  selectedCityTrigger : {},
  selectedCityCache : {},
  weatherDataFetch : {},
  serverLoader: false,
  putSmartFolderCacheRefetch : {},
  restoreItemsinWorkspaceModal : false,
  driveCacheLoader:false,
  resotrationFailedItems : [],
  driveTableHeader:{},
  putDriveTableHeader:{},
  driveTableHeaderData:[],
  cachedDriveTableHeader:{},
  adminSideBarLoader : false,
  adminCacheLoader:false,
  adminWorkSpaces:[],
  driveLoader:false,
  restorationWorkspace : {
    parentId : null,
    id : null
  },
  navigateFromQuickViewTriggerState : {} ,
  navigateSmartFolderTriggerState : {},
  homesmartFolderLoader : false,
  homeSmartFolderNavigateLoader : [],
  homeNavigateLoader : false,
  homeDriveCache:{},
  multipleFileDownloadTrigger : {},
  acceptInvitation:{},
  inviteError:false,
  inviteSuccess:false,
  pasteModal:false,
  restoreFeedbackRecycleBinFailureState : [],
  retryRestoreItemFromRecycleBin : {},
  MultipleRestoreRetryRestoreAction : {},
  errorInRetryRestore : [],
  successInRetryRestore : [],
  weatherDataonLang : {},
  pasteControllerState:{state:false, operationState:false, ogData:[], diffData:[]},
  chunkedUploadState : {},
  chunkCount : {
    chunkCnt : 0,
    totalCnt : 0
  },
  deleteErrorModal:{state:false,data:[]},
  deletePermSmrtFolder:{},
  currentWeatherLocation : {},
  openfilelocationpath : null,
  HomeNavigationStatus : false,
  ErrorOnTables : {},
  newDrive:{},
  driveData:[],
  newCacheData:{},
  smartFolderNodes:[],
  getNewSmartFolderChild:{},
  newFavFolder:{},
  newFavFolderData:[],
  favSmartFolderCacheChange:{},
  rebasedFavSmartFolder:[],
  getNewSmartFolderChildren : {},
  
  rebaseFavFolderData:[],
  newPortalTrigger:{},
  fetchRecycleNew:{},
  prevFilePermissionNonCache:{},
  commonCache:{},
  membersCache:{},
  labelDataCache:{},
  getNewDriveCache:{},
  getAdminCache:{},
  getMembersCache:{}
  
}
export const CustomizerSlice = createSlice({
  name: 'customizer',
  initialState,
  reducers: {

    getSliceData: (state) => state.payload.customizer.selectedportal,

    ChangeTopbarColor: (state, action) => {
      state.topbarBg = action.payload;
    },
    ToggleCustomizer: (state) => {
      state.customizerSidebar = !state.customizerSidebar;
    },
    ChangeDirection: (state, action) => {
      state.isRTL = action.payload;
    },
    ChangeDarkMode: (state, action) => {
      state.isDark = action.payload;
    },
    ToggleMiniSidebar: (state) => {
      state.isMiniSidebar = !state.isMiniSidebar;
    },
    ChangeSidebarColor: (state, action) => {
      state.sidebarBg = action.payload;
    },
    ToggleTopbar: (state, action) => {
      state.isTopbarFixed = action.payload;
    },
    ToggleMobileSidebar: (state) => {
      state.isMobileSidebar = !state.isMobileSidebar;
    },
    FixedSidebar: (state) => {
      state.isSidebarFixed = !state.isSidebarFixed;
    },
    ToggleHorizontal: (state) => {
      state.isLayoutHorizontal = !state.isLayoutHorizontal;
    },
    ToggleInnerRightPart: (state) => {
      state.isInnerRightPart = !state.isInnerRightPart;
    },
    UpdateSidebarMenuActive: (state, action) => {
      state.sidebarMenuActive = action.payload;
    },
    UpdateSidebarMenuNodeActive: (state, action) => {
      state.sidearMenuNodeActive = action.payload
    },
    UpdateconfirmDialogStatus: (state, action) => {
      state.confirmDialogStatus = action.payload;
    },
    updateMainMenuSelection: (state, action) => {
      state.mainMenuActive = action.payload;
    },
    UpdateswitchPortalStatus: (state, action) => {
      state.switchPortal = action.payload;
    },
    updateDriveItemRename: (state, action) => {
      state.driveItemRename = action.payload;
    },
    updateDriveItemLabels: (state, action) => {
      state.driveItemLabels = action.payload;
    },
    updateDriveItemVersions: (state, action) => {
      state.driveItemVersions = action.payload;
    },
    updateDriveItemPermission: (state, action) => {
      state.driveItemPermission = action.payload;
    },
    updateRecyclebinEmptyFlag: (state, action) => {
      state.recyclebinEmptyFlag = action.payload;
    },
    updateConfigureSmartFolderFlag: (state, action) => {
      state.configureSmartFolderFlag = action.payload;
    },
    updateDriveItemCheckout: (state, action) => {
      state.driveItemCheckout = action.payload;
    },
    updateDoubleClickedFolderItem: (state, action) => {
      state.doubleClickedFolderItem = action.payload;
    },
    updateDriveTileView: (state, action) => {
      state.driveTileView = action.payload;
    },

    Updateselectedportal: (state, action) => {
      state.selectedportal = action.payload;
    },
    BootsrapApp: (state, action) => {
      state.bootsrapApp = action.payload;
    },
    UpdateProfileInfo: (state, action) => {
      state.profileInfo = action.payload;
    },
    UpdatePortelUser: (state, action) => {
      state.portalUser = action.payload;
    },

    UpdateCurrentWeather: (state, action) => {
      state.currentWeather = action.payload
    },


    UpdateAdminStarterPivot: (state, action) => {
      const { pivotData, pivotStarter, url } = action.payload
      state.PivotAdminSidebar.pivotData = pivotData
      state.PivotAdminSidebar.pivotStarter = pivotStarter
      state.PivotAdminSidebar.url = url
    },
    UpdateInviteHeader: (state, action) => {
      const { id } = action.payload
      const finalId = state.TableHeaders.InviteTableHead.findIndex(item => {
        return item.id === id
      })
      state.TableHeaders.InviteTableHead[finalId] = action.payload
    },

    UpdateMemberHeader: (state, action) => {
      state.TableHeaders.WorkspaceMemberHead = action.payload
    },
    
    UpdateCurrentLanguage: (state, action) => {
      state.LanguageState = action.payload
    },
    UpdateCookiesAccept: (state, action) => {
      const { cookieState, popupState } = action.payload
      state.AcceptedCookieState.cookieState = cookieState
      state.AcceptedCookieState.popupState = popupState

    },
    UpdateLoading: (state, action) => {
      state.loading = action.payload
    },
    FetchWorkspacesFromPortal: (state, action) => {
      state.adminWorkspaceUserId = action.payload
    },
    UpdateAdminWorkspaces: (state, action) => {
      state.portalWorkspaces = action.payload
    },
    UpdateAllowedClients: (state, action) => {
      state.adminAllowedClientData = action.payload
    },
    UpdatePortalId: (state, action) => {
      state.portalId = action.payload;
    },
    UpdateInvitation: (state, action) => {
      state.portalId = action.payload;
    },
    PutProfileInfo: (state, action) => {
      state.profileInfo = action.payload;
    },
    UpdatePortalMembersUsers: (state, action) => {
      state.portalMemberData = action.payload
    },
    UpdatePortalMembersPermission: (state, action) => {
      state.portalMemberPermissionData = action.payload
    },

    UpdateQuickViewSmartFolders: (state, action) => {
      state.quickViewSmartFolders = action.payload;
    },
    initialAddQuickViewSmartFolders: (state, action) => {
      state.quickViewSmartFolders = action.payload;
    },
    AddQuickViewSmartFolder: (state, action) => {
      state.quickViewSmartFolders = action.payload
    },
    UpdateQuickViewSmartFolderTrigger: (state, action) => {
      state.quickViewSmartFolderTriggerState = action.payload
    },
    UdateSingleQuickViewSmartFolders: (state, action) => {
      state.putquickViewSmartFolders = action.payload
    },

    UpdateTableHeaderFunction: (state, action) => {
      const { name, data } = action.payload

      switch (name) {
        case 'Portal_TableHead':
          state.portalMemberTableHeader = data;
          break
        case 'Invite_Table_Head':
          state.invitationTableHeader = data;
          break
        case 'Label_TableHead':
          state.labelTableHeader = data;
          break
        case 'Workspace_TableHead':
          state.workspaceTableHeader = data;
          break
        case 'Group_TableHead':
          state.groupTableHeader = data;
          break
        case 'Member_TableHead' : 
          state.memberTableHeader = data;
          break
        default:
          console.log("nothing inside")
      }
    },
    UpdateswitchPortaladdServerCache: (state, action) => {
      state.selectedportal = action.payload
    },
    GetSwitchPortaladdServerCache: (state, action) => {
      state.userPortal = action.payload
    },
    getMembersData: (state, action) => {
      state.membersDetails = action.payload
    },
    addToMemberData: (state, action) => {
      state.fetchMembersDetails = action.payload;
    },
    getUsersMemberData: (state, action) => {
      state.userMembersDetails = action.payload
    },
    addToUserMemberDetails: (state, action) => {
      state.addUserDetails = action.payload
    },
    AddDataToSwitchPortal: (state, action) => {
      state.selectedportal = action.payload
    },
    UpdateMemberLeftMenu: (state, action) => {
      state.userPortalMenuData = action.payload
    },
    UpdateSwitchPortalCache: (state, action) => {
      state.userPortal = action.payload
    },
    AddToDriveSidebarDataCache: (state, action) => {
      state.DriveSidebarDataCache = action.payload
    },
    addMemberLeftmenu: (state, action) => {
      state.memberLeftMenu = action.payload
    },
    // member sidebar function
    fetchMenuMembersLeft: (state, action) => {
      state.getMembersLeftMenuDetails = action.payload
    },
    membersLeftMenuCacheonLoad: (state, action) => {
      state.membersLeftMenuCache = action.payload
    },
    // Group
    fetchGroupData: (state, action) => {
      state.GroupDetail = action.payload
    },
    UpdateGroupData: (state, action) => {
      state.GroupsData = action.payload
    },
    // 
    fetchMenuAdminLeft: (state, action) => {
      state.getAdminLeftMenuDetails = action.payload
    },
    addMenuAdminLeft: (state, action) => {
      state.adminLeftMenuCache = action.payload
    },
    updateadminLeftMenuCache: (state, action) => {
      state.adminLeftMenuCache = action.payload
    },
    adminLeftMenuCacheonLoad: (state, action) => {
      state.adminLeftMenuCache = action.payload
    },

    //
    getDashboardData: (state, action) => {
      state.activityHistoryData = action.payload
    },
    updateDashboardData: (state, action) => {
      state.activityHistoryElements = action.payload
    },
    UpdateInvitationData: (state, action) => {
      state.adminInvitationData = action.payload
    },
    PutTriggerSingleAllowedClientData: (state, action) => {
      state.SingleAllowedClient = action.payload;
    },
    UpdateLabelData: (state, action) => {
      state.adminLabelData = action.payload;
    },
    GetLabelData: (state, action) => {
      state.adminLabelTrigger = action.payload;
    },

    GetAllowedClientData: (state, action) => {
      state.allowedClientDetails = action.payload
    },
    GetHomeMemberData: (state, action) => {
      state.HomeMemberDetails = action.payload;
    },
    UpdateHomeMemberData: (state, action) => {
      state.HomeMembersData = action.payload;
    },
    FetchSingleFolderDetail: (state, action) => {
      state.fetchFolderDetail = action.payload
    },
    UpdateSingleFolderDetail: (state, action) => {
      state.FolderDetail = action.payload
    },
    TriggerPutSingleAllowedClients: (state, action) => {
      state.allowedClientDetails = action.payload
    },
    UpdateSingleAllowedClients: (state, action) => {
      state.allowedClientDetails = action.payload
    },
    FetchSingleFileDetail: (state, action) => {
      state.fetchFolderDetail = action.payload
    },
    UpdateSingleFileDetail: (state, action) => {
      state.FolderDetail = action.payload
    },
    FetchRecyclbinChildren: (state, action) => {
      state.fetchRecyclbin = action.payload
    },
    PutRecyclbinChildren: (state, action) => {
      state.putRecyclbin = action.payload
    },
    UpdatePortalMemberTableData: (state, action) => {
      state.portalMemberTableData = action?.payload
    },
    DeleteFromPortalMember: (state, action) => {
      state.DeletePortalMember = action.payload;
    },
    UpdateProfileImage: (state, action) => {
      state.profileImage = action.payload;
    },
    AddFavFolder: (state, action) => {
      state.favFolder = action.payload
    },
    AddSmartFolder: (state, action) => {
      state.smartFolder = action.payload
    },
    UpdateError: (state, action) => {
      const { status, message, modalOpen, statusCode } = action.payload;
      state.ErrorState.status = status;
      state.ErrorState.modalOpen = modalOpen;
      state.ErrorState.statusCode = statusCode;
      if (status && message === '') {
        state.ErrorState.message = 'Something Went Wrong!'
      }
      else if (!status && message === '') {
        state.ErrorState.message = 'Successfull'
      }
      else {
        state.ErrorState.message = message
      }

    },
    AddActivityData: (state, action) => {
      state.activityData = action.payload
    },
    updateActivityData : (state, action) => {
      state.activityData = action.payload
    },
    AddActivityOffset: (state, action) => {
      state.activityOffSet = action.payload
    },
    FetchActivityData: (state, action) => {
      state.getActivityData = action.payload
    },

    UpdateSmartFolderTree: (state, action) => {
      state.quickViewSmartFolderTree = action.payload;
    },
    AddfavSmartFolderCache: (state, action) => {
      state.addSmartFolderCache = action.payload;
    },
    PutfavSmartFolderCacheItem: (state, action) => {
      state.putSmartFolderCache = action.payload
    },
    UpdateFavSmartFolderCache: (state, action) => {
      state.favSmartFolderCache = action.payload
    },
    ReadFavSmartFolderCache: (state, action) => {
      state.insertSmartFolderCache = action.payload
    },
    AddfavFolderCache: (state, action) => {
      state.addFolderCache = action.payload
    },
    UpdateFavFolderCache: (state, action) => {
      state.favFolderCache = action.payload
    },
    fetchWorkspaceGroup: (state, action) => {
      state.triggerworkspaceGroups = action.payload
    },
    updateWorkspaceGroups: (state, action) => {
      state.workspaceGroups = action?.payload
    },
    AddInvite: (state, action) => {
      state.addInviteState = action?.payload
    },
    FetchWorkspacesRecursive: (state, action) => {
      state.adminWorkspaceUserId = action.payload
    },
    PostWorkSpace: (state, action) => {
      state.addWorkSpace = action.payload
    },
    StoreResWorkSpace: (state, action) => {
      state.resWorkSpace = action.payload
    },
    getLabel: (state, action) => {
      state.labels = action.payload
    },
    updateLabelStore: (state,action) => {
      state.labelStore = action.payload
    },
    addLabelStore: (state, action) => {
      state.labelStore = {entries : [...state.labelStore.entries,...action.payload.entries],
                          has_more : action.payload.has_more,
                          next_offset : action.payload.next_offset
                        }      
    },
    updateBreadcrubDrive: (state, action) => {
      state.breadcrubDrive = action.payload
    },
    getDriveCache: (state, action) => {
      state.fetchDriveCache = action.payload
    },
    addDriveSidebarCache: (state, action) => {
      state.driveSidebarCache = action.payload
    },

    DeleteFromInvitations: (state, action) => {
      state.DeleteInvitation = action?.payload
    },
    updateDeleteConfirmDialogStatus: (state, action) => {
      state.confirmDeleteDialogStatus = action.payload
    },
    updateConfirmationDeleteStatus: (state) => {
      state.confirmationDelete = !state.confirmationDelete
    },
    postWorkSpaceCopy: (state, action) => {
      state.sentWorkSpaceCopy = action.payload
    },
    sentRemoveWorkSpace: (state, action) => {
      state.removeWorkSpace = action.payload
    },
    addApiStatus: (state, action) => {
      state.commonApiStatus = action.payload
    },
    updateresendInvite: (state, action) => {
      state.resendInvite = action?.payload
    },
    putRenameWorkSpace: (state, action) => {
      state.renameWorkSpace = action.payload
    },
    addDefaultSync: (state, action) => {
      state.sendDefaultSync = action.payload
    },
    updateToastDialogModal: (state, action) => {
      state.toastDialogModal = action.payload
    },
    updateWorkspacePermissionFunction: (state, action) => {
      state.WorkspacePermission = action.payload
    },
    addLabelTrigger: (state, action) => {
      state.labeladdState = action.payload
    },
    addUserWorkSpace: (state, action) => {
      state.addUserWrkSpace = action.payload
    },
    deleteUserFromWorkspace: (state, action) => {
      state.workspaceUserforDeleteState = action.payload
    },
    updateLabelTrigger: (state, action) => {
      state.labelupdateState = action.payload
    },
    updatePortalPermissionTrigger: (state, action) => {
      state.portalPermissionState = action.payload;
    },
    deleteLabelTrigger: (state, action) => {
      state.labelDeleteState = action?.payload
    },
    sentRemoveGroup: (state, action) => {
      state.removeGroup = action.payload
    },
    sentAddGroup: (state, action) => {
      state.addGroup = action.payload
    },
    getGroupMembers: (state, action) => {
      state.groupMembers = action.payload
    },
    storeGroupMembers: (state, action) => {
      state.saveGroupMembers = action.payload
    },
    sendGetWorkspcMembers: (state, action) => {
      state.fetchWorkspaceMember = action.payload
    },
    storeWorkSpacemembers: (state, action) => {
      state.workSpaceMemebers = action.payload
    },
    sentDeleteGroupMember: (state, action) => {
      state.deleteGroupMember = action.payload
    },
    sentAddGrpMember: (state, action) => {
      state.sentGrpMember = action.payload
    },
    setGrpLoader: (state, action) => {
      state.grpLoader = action.payload
    },
    updateMultipleAllowedClient: (state, action) => {
      state.multipleAllowedClientState = action.payload
    },
    dndFilePut: (state, action) => {
      state.dndFileUpload = action.payload
    },
    fetchDriveItemsRequest: (state, action) => {
      state.fetchDriveItems = action.payload
    },
    updateDriveItems: (state, action) => {
      state.driveItems = action.payload
    },
    updateDriveLoading: (state, action) => {
      state.driveLoading = action.payload
    },
    updateDriveError: (state, action) => {
      state.driveError = action.payload
    },

    sentSearchFile: (state, action) => {
      state.searchFile = action.payload
    },
    setSavedFiles: (state, action) => {
      state.savedFiles = action.payload
    },
    setEditGroupName: (state, action) => {
      state.editGroupName = action.payload
    },
    updateLoggedUserPermissionTrigger: (state, action) => {
      state.loggedUserPermission = action.payload
    },
    updateLoggedInUserPermissionState: (state, action) => {
      state.loggedInUserPermissionState = action.payload
    },
    updateRestoreItemFromRecyclebin : (state,action) => {
      state.RestoreItemFromRecycleBinState = action.payload
    },
    clearRecycleBinTrigger : (state,action) => {
      state.clearRecyclebinTriggerState = action.payload
    },
    createSmartFolderTrigger: (state, action) => {
      state.createSmartFolderState = action.payload
    },
    updateFetchSmartFolderTrigger: (state, action) => {
      state.fetchSmartFolderTriggerState = action.payload
    },
    updateFetchSmartFolder: (state, action) => {
      state.fetchSmartFolderState = action.payload
    },
    updateSmartFolderMetaFieldTrigger : (state,action) => {
      state.fetchSmartFolderMetaFieldState = action.payload
    },
    populateSmartFolderMetaFieldData : (state,action) => {
      state.populateSmartFolderMetaFieldState = action.payload
    },
    deleteRecyclebinRow: (state,action) => {
      state.recycleBinRow = action.payload
    },
    updateRecyclebinItemFlag: (state,action) => {
      state.recyceBinItemFlag = action.payload
    },
    updateDeleteworkSpaceItems: (state,action) => {
      state.deleteWorkSpaceItems  = action.payload
    },
    configureSmartFnotrolderTrigger : (state,action) => {
      state.configureSmartFolder = action?.payload
    },
    getEmail: (state,action) => {
      state.emailLink = action.payload
    },
    updateStoreEmail : (state,action) => {
      state.storeEmail = action.payload
    },
    UpdateDriveSelectedItem: (state,action) => {
      state.driveSelectedItem  = action.payload
    },
    UpdateDeleteSmartFolderTrigger : (state,action) => {
      state.deleteSmartFolder = action.payload
    },
    newFileFolder: (state,action) => {
      state.fileFolder = action.payload
    },
    Updatecreatemetafieldexistingsmartfolderstate : (state,action) => {
      state.createmetafieldexistingsmartfolderstate = action.payload
    },
    UpdatelinkAcces : (state,action) => {
      state.linkAccesPayload = action.payload
    },
    DeleteMetaFieldSmartFolderTrigger : (state,action) => {
      state.deleteMetaFieldSmartFolderState = action?.payload
    },
    updateFilePreview: (state,action) => {
      state.filePreview = action.payload
    },
    updateFilePeviewLink: (state,action) => {
      state.filePreviewLink = action.payload
    },
    updateViewId: (state,action) => {
      state.viewId = action.payload
    },
    updateFolderDownload: (state,action) => {
      state.folderDownload = action.payload
    },
    updateFolderLink: (state,action) => {
      state.folderDownloadLink = action.payload
    },
    updateDriveEditOperations: (state,action) => {
      state.driveEditOperations = action.payload
    },
    updateDrivePasteOperations: (state,action) => {
      state.drivePasteOperations = action.payload
    },
    updateFilePermission: (state,action) => {
      state.filePermission = action.payload
    },
    updateStoreFolderPermission: (state,action) => {
      state.storeFolderPermission = action.payload
    },
    updateUserFilePermission:(state,action)=> {
      state.userFilePermission = action.payload
    },
    updateFolderPermission:(state,action)=>{
      state.userFolderPermission = action.payload
    },
    updateGetVersions: (state,action) => {
      state.getVersions = action.payload
    },
    updateStoreVersions: (state,action) => {
      state.storeVersions = action.payload
    },
    sentFileComment: (state,action) => {
      state.fileComment = action.payload
    },
    setEditComment: (state,action) => {
      state.editComment = action.payload
    },
    UpdateSmartFolderSelectedItem: (state,action)=> {
      state.smartFolderSelectedItem = action.payload
    },
    UpdatedriveSendLinkState : (state , action) => {
      state.driveSendLinkState = action?.payload
    },
    smartFolderMetaFieldTriggerForFilterCrieteriaTrigger : (state,action) => {
      state.smartFolderMetaFieldFilterCrieteriaTrigger = action.payload
    },
    updatesmartFolderMetaFieldTriggerForFilterCrieteria : (state,action) => {
      state.smartFolderMetaFieldFilterCrieteriaState = action.payload
    },
    updateUserPermission: (state,action) => {
      state.userPermission = action.payload
    },
    sentUpdatedPermission:(state,action)=> {
      state.updatedPermission = action.payload
    },
    updatepostDriveLabelState : (state,action) => {
      state.postDriveLabelState = action.payload
    },
    updateputDriveLabelState : (state,action) => {
      state.putDriveLabelState = action.payload
    },
    updatedeleteDriveLabelState : (state,action) => {
      state.deleteDriveLabelState = action.payload
    },
    updatefFleOnOfStatus : (state,action) => {
      state.fileOnOfStatus = action.payload
    },
    updatedeleteFileFolderDriveState : (state,action) => {
      state.deleteFileFolderDriveState = action.payload
    },
    updatedrenameFileFolderDriveState : (state,action) => {
      state.renameFileFolderDriveState = action.payload
    },
    updatePreviewStatus:(state,action) => {
      state.previewStatus = action.payload
    },
    updateDeleteSmartFolderState : (state,action) => {
      state.deleteSmartFolderState = action.payload
    },
    configureSmartFolderTrigger : (state,action) => {
      state.configureSmartFolderTriggerState = action.payload
    },
    updateComponentCardName : (state,action) => {
      state.ComponentCardName = action.payload
    },
    updateRecycleBinNavState : (state,action) => {
      state.recyclebinNavStates = action.payload
    },
    updateLabelEditStatus : (state,action) => {
      const {disabled,dispatch} = action.payload
      state.LabelEditStatus.disabled = disabled
      state.LabelEditStatus.dispatch = dispatch
    },
    updatememberListInvite : (state,action) => {
      state.memberListInvite = action.payload
    },
    updatememberInviteSuccess : (state,action) => {
      state.memberInviteSuccess = action.payload
    },
    updatememberInviteError: (state,action) => {
      state.memberInviteError = action.payload
    }, 
    updateBreadCrumbDisable: (state,action) => {
      state.breadCrumbDisable = action.payload
    },
    updateAdminInvitationToastState : (state,action) => {
      state.adminInvitationToastState = action.payload
    },
    updateCheckDeleteState : (state,action) => {
      state.checkDeleteState = action.payload
    },
    updateDeleteState :(state,action) => {
      state.deleteState = action.payload
    },
    updateSendLinkAccess : (state,action) => {
      state.sendLinkAccess = action.payload
    },
    updateCopyLinkAccess : (state,action) => {
      state.copyLinkAccess = action.payload
    },
    updateModalChangeStatus : (state,action) =>{
    state.ChangeModalStatus = action.payload
  },
    updatePortalWorkspacesLoading : (state,action) => {
      state.portalWorkspacesLoading = action.payload
    },
    
    dndFilePutNew : (state,action) => {
      state.dndFileUploadNew = action.payload
    },
    UpdatedriveUploadError : (state,action) => {
      state.driveUploadError = action.payload
    },
    UpdatePopupOnSubmission : (state,action) => {
      state.popupOnSubmission = action.payload
    },
    UpdateDriveUploadErrorSwitch : (state,action) => {
      state.driveUploadErrorSwitch = action.payload
    },
    UpdateDriveUploadSuccess : (state,action) => {
      state.driveUploadSuccess = action.payload
    },
    updateCommonLoader:(state,action) => {
      state.commonLoader = action.payload
    },
    updateDeleteCache : (state,action) => {
      state.deleteCache = action.payload
    },
    updateEmptyPortalAlert : (state,action) => {
      state.emptyPortalAlert = action.payload
    },
    updateBootsrapLoader : (state,action) => {
      state.bootsrapLoader = action.payload
    },
    updateSignout: (state,action) => {
      state.signout = action.payload
    },
    updateProfileState: (state,action) => {
      state.profileState = action.payload
    },
    updatesuccessMetaDataSmartFolder : (state,action) => {
      state.successMetaDataSmartFolder = action.payload
    },
    UpdateAppDateTimeFormat : (state,action) => {
      const {dateFormat,timeFormat} = action?.payload
      state.AppDateTimeState={
        AppDateFormat : dateFormat,
        AppTimeFormat : timeFormat
      }
    },
    UpdateSmartFolderNameStateTrigger : (state ,action) => {
      state.SmartFolderNameStateTrigger = action?.payload
    },
    UpdateLoggedUserPortalPermissionState : (state,action) => {
    state.LoggedUserPortalPermissionState = action.payload
    },
    UpdateLoggedUserWorkspacePermissionState : (state,action) => {
      state.LoggedUserWorkspacePermissionState = action.payload
    },
    UpdateLoggedUserPortalPermissionTrigger : (state,action) => {
      state.LoggedUserPortalPermissionTrigger = action.payload
    },
    UpdateLoggedUserWorkspacePermissionTrigger : (state,action) => {
      state.LoggedUserWorkspacePermissionTrigger = action.payload
    },
    UpdateDragEventData : (state,action) => {
      state.DragEventData = action.payload
    },
    UpdateDriveDragMove : (state,action) => {
      state.DragMoveData = action.payload
    },
    updateDriveViewModal : (state,action) => {
      state.driveViewModal = action.payload
    },
    UpdateUploadFailedStatus : (state,action) => {
      const {status} = action.payload
      state.uploadFailedStatus.status = status
    },
    UpdateUploadPopupStatus : (state,action) => {
      const {status,modalOpen} = action.payload
      state.uploadPopupFailedStatus.status = status
      state.uploadPopupFailedStatus.modalOpen = modalOpen
    },
    UpdateIcoordinatorAbout : (state,action) => {
      state.IcoordinatorAbout = action.payload
    },
    updateDeletedItem : (state,action) => {
      state.driveDeleteItem = action.payload
    },
    updateHighestFileFolder : (state,action) => {
      state.getHighestFileFolder = action.payload
    },
    updateHighestFileFolderPrem : (state,action) => {
      state.highestFileFolderPrem = action.payload
    },
    updateGetprevFileState : (state,action) => {
      state.getprevFileState = action.payload
    },
    updatePrevFileState : (state,action) => {
      state.prevFileState = action.payload
    },
    updateLockState : (state,action) => {
      state.lockState = action.payload
    },
    UpdateAllCities : (state,action) => {
      state.AllCities = action.payload
    },
    UpdateSelectedCity : (state,action) => {
      state.SelectedCity = action.payload;
    },
    SelectedCityTrigger : (state,action) => {
      state.selectedCityTrigger = action .payload
    },
    addselectedCityCache: (state, action) => {
      state.selectedCityCache = action.payload
    },
    updateselectedCityCache: (state, action) => {
      state.selectedCityCache = action.payload
    },

    selectedCityCacheLoad: (state, action) => {
      state.selectedCityCache = action.payload
    },
    updateweatherDataFetch : (state,action) => {
      state.weatherDataFetch = action.payload
    },
    updateServerLoader : (state,action) => {
      state.serverLoader = action.payload
    },
    PutfavSmartFolderCacheRefetchItem: (state, action) => {
      state.putSmartFolderCacheRefetch = action.payload
    },
    updaterestoreItemsinWorkspaceModal : (state,action) => {
      state.restoreItemsinWorkspaceModal = action.payload
    },
    updateresotrationFailedItems : (state,action) => {
      state.resotrationFailedItems = action.payload
    },
    AddrestorationWorkspace : (state,action) => {
      state.restorationWorkspace = action.payload
    },
    getDriveTableheader:(state,action) => {
      state.driveTableHeaderData = action.payload
    },
    updateDriveCacheLoader: (state,action) => {
      state.driveCacheLoader = action.payload
    },
    updateNavigateUrl : (state,action) => {
      state.navigateFromQuickViewTriggerState = action.payload
    },
    updateNavigateSmartFolders : (state,action) => {
      state.navigateSmartFolderTriggerState = action.payload
    },
    updateSideBarLoader: (state,action) => {
      state.adminSideBarLoader = action.payload
    },
    udpateAdminCacheLoader: (state,action) => {
      state.adminCacheLoader = action.payload
    },
    updateAdminSideBar: (state,action) => {
      state.adminWorkSpaces = action.payload
    },
    updateDriveLoader: (state,action) => {
      state.driveLoader = action.payload
    },
    
    updateHomesmartFolderLoader : (state,action) => {
      state.homesmartFolderLoader = action.payload
    },
    updatehomeSmartFolderNavigateLoader : (state,action) => {
      state.homeSmartFolderNavigateLoader = action.payload
    },
    updateHomeNavigateLoader: (state,action) => {
      state.homeNavigateLoader = action.payload
    },
    updateDriveSidebarCacheHome: (state,action) => {
      state.homeDriveCache = action.payload
    },
    updateMultipleDownload : (state,action) => {
      state.multipleFileDownloadTrigger = action.payload
    },
    updateAcceptInvitations: (state,action) => {
      state.acceptInvitation = action.payload
    },
    updateInviteError : (state,action) => {
      state.inviteError = action.payload
    },
    updateInviteSuccess : (state,action) => {
      state.inviteSuccess = action.payload
    },
    updatePasteModal : (state,action) => {
      state.pasteModal = action.payload
    },
    updaterestoreFeedbackRecycleBinFailureState : (state,action) => {
      state.restoreFeedbackRecycleBinFailureState = action.payload;
    },
    updateretryRestoreItemFromRecyclebin : (state,action) => {
      state.retryRestoreItemFromRecycleBin = action.payload
    },
    updateMultipleRestoreRetryRestoreAction : (state,action) => {
      state.MultipleRestoreRetryRestoreAction = action.payload
    },

   updateErrorInRetryRestore : (state,action) => {
    state.errorInRetryRestore = action.payload
   },
   updateSuccessInRetryRestore : (state,action) => {
    state.successInRetryRestore = action.payload
   },
   updateWeatherDataonLang : (state,action) => {
    state.weatherDataonLang = action.payload
   },
   updatePasteController : (state,action) => {
    state.pasteControllerState = action.payload
   },
   updateChunkedUploadState : (state,action) => {
    state.chunkedUploadState = action.payload
   },
   updateChunkCount : (state,action) => {
    const {chunkCount,totalCount} = action.payload
    state.chunkCount.chunkCnt = chunkCount
    state.chunkCount.totalCnt = totalCount
    
   },
   updateDeleteErrorModal:(state,action) => {
    state.deleteErrorModal = action.payload
   },
  updateDeleteSmrtFolder:(state,action) => {
    state.deletePermSmrtFolder = action.payload
  },
  updateErrorOnTables : (state,action) => {
    state.ErrorOnTables = action.payload
  },
  updateCurrentWeatherLocation : (state,action) => {
    state.currentWeatherLocation = action.payload
  },
  updateOpenFileLocationPath : (state,action) => {
    state.openfilelocationpath = action.payload
  },
  updateNewDrive : (state,action) => {
    state.newDrive = action.payload
  },
  updateDriveData:(state,action) => {
    state.driveData = [...action.payload]
  },
  updateGetNewCacheData:(state,action) => {
    state.newCacheData = action.payload
  },
  updateSmartFolderNodes:(state,action) => {
    state.smartFolderNodes = action.payload
  },
  updateSmartFolderChildren:(state,action) => {
    state.getNewSmartFolderChild = action.payload
  },
  updateNewFavFolder:(state,action) => {
    state.newFavFolder = action.payload
  },
  updateNewFavFolderData:(state,action) =>{
    state.newFavFolderData = action.payload
  },
  updateFavSmartCacheChange:(state,action) =>{
    state.favSmartFolderCacheChange = action.payload
  },
  updateRebasedFavSmart:(state,action)=>{
    state.rebasedFavSmartFolder = action.payload
  },
  updateGetNewSmartFolder:(state,action) => {
    state.getNewSmartFolderChildren = action.payload
  },

  rebasedFavFolder:(state,action) =>{
    state.rebaseFavFolderData = action.payload
  },
  updateHomeNavigationStatus : (state,action) => {
    state.HomeNavigationStatus = action.payload
  },
  updateNewPortalTrigger:(state,action) => {
    state.newPortalTrigger = action.payload
  },
  updateFetchRecycleNew:(state,action) => {
    state.fetchRecycleNew = action.payload
  },
  updatePrevPermission:(state,action) => {
    state.prevFilePermissionNonCache = action.payload
  }, 
  updateCommonCache:(state,action) => {
    state.commonCache = action.payload
  },
  updateMembersCache:(state,action)=>{
    state.membersCache = action.payload
  },
  updateLabelCache:(state,action) => {
    state.labelDataCache = action.payload
  },
  updateNewDriveCache:(state,action) => {
    state.getNewDriveCache = action.payload
  },
  updateMembersNewCache:(state,action) => {
    state.getMembersCache = action.payload
  },
  updateAdminCache:(state,action) => {
    state.getAdminCache = action.payload
  }

  },
});

export const {
  ChangeTopbarColor,
  ToggleCustomizer,
  ChangeDirection,
  ChangeDarkMode,
  ToggleMiniSidebar,
  ChangeSidebarColor,
  ToggleTopbar,
  ToggleMobileSidebar,
  FixedSidebar,
  ToggleHorizontal,
  ToggleInnerRightPart,
  UpdateSidebarMenuActive,
  UpdateSidebarMenuNodeActive,
  UpdateconfirmDialogStatus,
  updateMainMenuSelection,
  UpdateswitchPortalStatus,
  updateDriveItemRename,
  updateDriveItemLabels,
  updateDriveItemVersions,
  updateDriveItemPermission,
  updateRecyclebinEmptyFlag,
  updateConfigureSmartFolderFlag,
  updateDriveItemCheckout,
  updateDoubleClickedFolderItem,
  updateDriveTileView,
  Updateselectedportal,
  BootsrapApp,
  UpdateProfileInfo,
  UpdatePortelUser,
  UpdatePivot,
  UpdateCurrentWeather,
  UpdateAdminStarterPivot,
  UpdateInviteHeader,
  UpdateMemberHeader,
  UpdateCurrentLanguage,
  UpdateCookiesAccept,
  UpdateLoading,
  FetchWorkspacesFromPortal,
  UpdateAdminWorkspaces,
  PutProfileInfo,
  UpdatePortalMembersUsers,
  UpdatePortalMembersPermission,
  UpdateQuickViewSmartFolders,
  UpdateQuickViewSmartFolderTrigger,
  UdateSingleQuickViewSmartFolders,
  UpdatePortalId,
  UpdateTableHeaderFunction,
  UpdateTableHeaderDetails,
  UpdateswitchPortaladdServerCache,
  AddQuickViewSmartFolder,
  initialAddQuickViewSmartFolders,
  AddQuickViewFolder,
  getMembersData,
  addToMemberData,
  GetSwitchPortaladdServerCache,
  AddDataToSwitchPortal,
  AddToDriveSidebarDataCache,
  getUsersMemberData,
  addToUserMemberDetails,
  UpdateSwitchPortalCache,
  UpdateMemberLeftMenu,
  getDashboardData,
  updateDashboardData,
  UpdateInvitation,
  UpdateInvitationData,
  PutTriggerSingleAllowedClientData,
  UpdateLabelData,
  GetLabelData,
  fetchMenuMembersLeft,
  membersLeftMenuCacheonLoad,
  UpdateAllowedClients,
  addMenuAdminLeft,
  updateadminLeftMenuCache,
  adminLeftMenuCacheonLoad,
  GetAllowedClientData,
  UpdateGroupData,
  fetchGroupData,
  GetHomeMemberData,
  UpdateHomeMemberData,
  FetchSingleFolderDetail,
  UpdateSingleFolderDetail,
  TriggerPutSingleAllowedClients,
  UpdateSingleAllowedClients,
  FetchSingleFileDetail,
  UpdateSingleFileDetail,
  DeleteFromPortalMember,
  PutRecyclbinChildren,
  FetchRecyclbinChildren,
  UpdatePortalMemberTableData,
  UpdateProfileImage,
  AddFavFolder,
  AddSmartFolder,
  UpdateError,
  AddActivityData,
  AddActivityOffset,
  FetchActivityData,
  UpdateSmartFolderTree,
  AddfavSmartFolderCache,
  PutfavSmartFolderCacheItem,
  GetFavSmartFolderCache,
  UpdateFavSmartFolderCache,
  ReadFavSmartFolderCache,
  AddfavFolderCache,
  UpdateFavFolderCache,
  fetchWorkspaceGroup,
  updateWorkspaceGroups,
  AddInvite,
  PostWorkSpace,
  StoreResWorkSpace,
  FetchWorkspacesRecursive,
  getLabel,
  addLabelStore,
  updateBreadcrubDrive,
  getDriveCache,
  addDriveSidebarCache,
  DeleteFromInvitations,
  updateDeleteConfirmDialogStatus,
  updateConfirmationDeleteStatus,
  postWorkSpaceCopy,
  sentRemoveWorkSpace,
  addApiStatus,
  updateresendInvite,
  putRenameWorkSpace,
  addDefaultSync,
  updateToastDialogModal,
  updateWorkspacePermissionFunction,
  getSliceData,
  addLabelTrigger,
  deleteUserFromWorkspace,
  addUserWorkSpace,
  updateLabelTrigger,
  updatePortalPermissionTrigger,
  deleteLabelTrigger,
  sentRemoveGroup,
  sentAddGroup,
  getGroupMembers,
  storeGroupMembers,
  sentSearchFile,
  setSavedFiles,
  sendGetWorkspcMembers,
  storeWorkSpacemembers,
  sentDeleteGroupMember,
  sentAddGrpMember,
  setGrpLoader,
  updateMultipleAllowedClient,
  dndFilePut,
  updateDriveItems,
  fetchDriveItemsRequest,
  updateDriveLoading,
  updateDriveError,
  setEditGroupName,
  updateLoggedUserPermissionTrigger,
  updateLoggedInUserPermissionState,
  createSmartFolderTrigger,
  updateFetchSmartFolderTrigger,
  updateFetchSmartFolder,
  updateRestoreItemFromRecyclebin,
  clearRecycleBinTrigger,
  updateSmartFolderMetaFieldTrigger,
  populateSmartFolderMetaFieldData,
  deleteRecyclebinRow,
  updateRecyclebinItemFlag,
  updateDeleteworkSpaceItems,
  configureSmartFolderTrigger,
  Updatecreatemetafieldexistingsmartfolderstate,
  getEmail,
  updateStoreEmail,
  UpdateDriveSelectedItem,
  UpdateDeleteSmartFolderTrigger,
  UpdatelinkAcces,
  DeleteMetaFieldSmartFolderTrigger,
  updateFolderDownload,
  updateFolderLink,
  newFileFolder,
  updateFilePreview,
  updateFilePeviewLink,
  updateViewId,
  updateDriveEditOperations,
  updateDrivePasteOperations,
  updateFilePermission,
  updateStoreFolderPermission,
  updateUserFilePermission,
  updateFolderPermission,
  updateGetVersions,
  updateStoreVersions,
  sentFileComment,
  setEditComment,
  UpdateSmartFolderSelectedItem,
  UpdatedriveSendLinkState,
  smartFolderMetaFieldTriggerForFilterCrieteriaTrigger,
  updatesmartFolderMetaFieldTriggerForFilterCrieteria,
  updateUserPermission,
  sentUpdatedPermission,
  updatepostDriveLabelState,
  updateputDriveLabelState,
  updatedeleteDriveLabelState,
  updatefFleOnOfStatus,
  updatedeleteFileFolderDriveState,
  updatedrenameFileFolderDriveState,
  updatePreviewStatus,
  updateDeleteSmartFolderState,
  updateComponentCardName,
  updateRecycleBinNavState,
  updateLabelEditStatus,
  updatememberListInvite,
  updatememberInviteSuccess,
  updatememberInviteError,
  updateBreadCrumbDisable,
  updateAdminInvitationToastState,
  updateCheckDeleteState,
  updateDeleteState,
  updateSendLinkAccess,
  updateCopyLinkAccess,
  dndFilePutNew,
  UpdatedriveUploadError,
  UpdatePopupOnSubmission,
  UpdateDriveUploadErrorSwitch,
  UpdateDriveUploadSuccess,
  updateLabelStore,
  updateActivityData,
  updateModalChangeStatus,
  updatePortalWorkspacesLoading,
  updateEmptyPortalAlert,
  updateBootsrapLoader,
  updateSignout,
  updateCommonLoader,
  updateDeleteCache,
  updateProfileState,
  updatesuccessMetaDataSmartFolder,
  UpdateAppDateTimeFormat,
  UpdateSmartFolderNameStateTrigger,
  UpdateLoggedUserPortalPermissionState,
  UpdateLoggedUserWorkspacePermissionState,
  UpdateLoggedUserPortalPermissionTrigger,
  UpdateLoggedUserWorkspacePermissionTrigger,
  UpdateDragEventData,
  UpdateDriveDragMove,
  updateDriveViewModal,
  UpdateUploadFailedStatus,
  UpdateUploadPopupStatus,
  UpdateIcoordinatorAbout,
  updateDeletedItem,
  updateHighestFileFolder,
  updateHighestFileFolderPrem,
  updateGetprevFileState,
  updatePrevFileState,
  updateLockState,
  UpdateAllCities,
  UpdateSelectedCity,
  SelectedCityTrigger,
  updateweatherDataFetch,
  updateServerLoader,
  PutfavSmartFolderCacheRefetchItem,
  updaterestoreItemsinWorkspaceModal,
  updateresotrationFailedItems,
  AddrestorationWorkspace,
  getDriveTableheader,
  updateDriveCacheLoader,
  updateNavigateUrl,
  updateNavigateSmartFolders,
  updateSideBarLoader,
  udpateAdminCacheLoader,
  updateAdminSideBar,
  updateDriveLoader,
  smartFolderCacheHomeTrigger,
  updateHomesmartFolderLoader,
  updatehomeSmartFolderNavigateLoader,
  updateHomeNavigateLoader,
  updateDriveSidebarCacheHome,
  updateMultipleDownload,
  updateAcceptInvitations,
  updateInviteError,
  updateInviteSuccess,
  updatePasteModal,
  updaterestoreFeedbackRecycleBinFailureState,
  updateretryRestoreItemFromRecyclebin,
  updateMultipleRestoreRetryRestoreAction,
  updateErrorInRetryRestore,
  updateSuccessInRetryRestore,
  updateWeatherDataonLang,
  updatePasteController,
  updateChunkedUploadState,
  updateChunkCount,
  updateDeleteErrorModal,
  updateDeleteSmrtFolder,
  updateCurrentWeatherLocation,
  updateOpenFileLocationPath,
  updateErrorOnTables,
  updateNewDrive,
  updateDriveData,
  updateGetNewCacheData,
  updateSmartFolderNodes,
  updateSmartFolderChildren,
  updateNewFavFolder,
  updateNewFavFolderData,
  updateFavSmartCacheChange,
  updateRebasedFavSmart,
  updateGetNewSmartFolder,
  rebasedFavFolder,
  updateHomeNavigationStatus,
  updateNewPortalTrigger,
  updateFetchRecycleNew,
  updatePrevPermission,
  updateCommonCache,
  updateMembersCache,
  updateLabelCache,
  updateNewDriveCache,
  updateMembersNewCache,
  updateAdminCache
} = CustomizerSlice.actions;

export default CustomizerSlice.reducer; 
